import VideoList from "./VideoList";
import {useContext, useEffect, useRef, useState} from "react";
import AppContext from "../context/AppContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import VideoPlayer from "./video-player/VideoPlayer";
import TagList from "./TagList";

import './VideoLayer.scss'
import {Link} from "react-router-dom";
import {getVideoLink} from "../lib/static";

let layer: HTMLDivElement|null = null

export default () => {
    const {
        dirLoading, selectedVideo, setSelectedVideo, videoLoop, setVideoLoop,
        videoAutoPlay, setVideoAutoPlay, files, nextVideo, prevVideo,
    } = useContext(AppContext)

    const containerRef = useRef<HTMLDivElement>(null)
    const [ fullScreen, setFullScreen ] = useState<boolean>(false)
    const [ nextRequested, setNextRequested ] = useState<boolean>(false)
    const [ hasHover, setHasHover ] = useState<boolean>(false)
    const [ lastHoverTimout, setLastHoverTimeout ] = useState<number | null>(null)
    const [ ctrlPressed, setCtrlPressed ] = useState<boolean>(false)
    const [scrollPosition, setScrollPosition] = useState<number>(0);

    const ctrlRef = useRef<boolean>(false)
    ctrlRef.current = ctrlPressed

    const nextVideoRef = useRef(nextVideo)
    nextVideoRef.current = nextVideo

    const prevVideoRef = useRef(prevVideo)
    prevVideoRef.current = prevVideo

    const onLayerOpen = (el: HTMLDivElement) => {
        layer = el
    }

    const onSmallListLoaded = () => {
        if (layer) {
            setTimeout(() => {
                layer?.querySelector('.file-list')?.querySelector('.file-preview.selected')?.scrollIntoView()
            }, 0)
        }
    }

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Control') {
                setCtrlPressed(true)
            }
        }

        const onKeyUp = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Control':
                    setCtrlPressed(false)
                    break
                case 'ArrowLeft':
                    if (!ctrlRef.current) {
                        break
                    }
                    startPrev(e)
                    break
                case 'ArrowRight':
                    if (!ctrlRef.current) {
                        break
                    }
                    startNext(e)
                    break
            }
        }

        window.addEventListener('keydown', onKeyDown)
        window.addEventListener('keyup', onKeyUp)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
            window.removeEventListener('keyup', onKeyUp)
        }
    }, []);

    useEffect(() => {
        onSmallListLoaded()
    }, [ selectedVideo ]);

    useEffect(() => {
        if (nextRequested) {
            setNextRequested(false)
            startNext()
        }
    }, [ nextRequested ]);

    useEffect(() => {
        if (selectedVideo?.fileType ==='photo' && videoAutoPlay) {
            setTimeout(() => {
                setNextRequested(true)
            }, 5000)
        }
        if (videoAutoPlay) {
            setVideoLoop(false)
        }
    }, [ selectedVideo, videoAutoPlay ]);

    useEffect(() => {
        if (videoLoop) {
            setVideoAutoPlay(false)
        }
    }, [ videoLoop ]);

    const onClose = (e: any) => {
        e.preventDefault()
        setSelectedVideo(null)
        window.history.pushState(null, '', window.location.href.split('?')[0])
    }

    const startPrev = (e: any|undefined = undefined) => {
        if (e) {
            e.preventDefault()
        }
        if (prevVideoRef.current) {
            setSelectedVideo(prevVideoRef.current)
            window.history.pushState(null, '', getVideoLink(prevVideoRef.current.hash))
        }
    }

    const startNext = (e: any|undefined = undefined) => {
        if (e) {
            e.preventDefault()
        }
        if (nextVideoRef.current) {
            setSelectedVideo(nextVideoRef.current)
            window.history.pushState(null, '', getVideoLink(nextVideoRef.current.hash))
        }
    }

    const onVideoEnded = () => {
        if (videoAutoPlay) {
            startNext()
        }
    }

    const toggleFullScreen = (e?: any) => {
        e.preventDefault()
        if (!containerRef.current) {
            return
        }
        const container = containerRef.current
        if (!document.fullscreenElement) {
            container.requestFullscreen()
            console.log('set', window.scrollY)
            setScrollPosition(window.scrollY)
            setFullScreen(true)
        }
        else {
            document.exitFullscreen();
            setTimeout(() => window.scrollTo(0, scrollPosition), 10)
            console.log('restore', scrollPosition)
            setFullScreen(false)
        }
    }

    const nav = (
        <nav>
            {prevVideo ? (
                <a href="#" onClick={startPrev}>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </a>
            ) : <div/>}

            {nextVideo ? (
                <a href="#" onClick={startNext}>
                    <FontAwesomeIcon icon={faChevronRight}/>
                </a>
            ) : <div/>}
        </nav>
    )

    const onHoverStart = () => {
        setHasHover(true)

        if (lastHoverTimout) {
            window.clearTimeout(lastHoverTimout)
        }

        setLastHoverTimeout(window.setTimeout(() => {
            onHoverEnd()
        }, 3000))
    }

    const onHoverEnd = () => {
        setHasHover(false)

        if (lastHoverTimout) {
            window.clearTimeout(lastHoverTimout)
        }
    }

    const classNames = ['content has-selected-video video-layer']
    if (hasHover) {
        classNames.push('hover')
    }

    return (
        <>
            {selectedVideo && (
                <div
                    className={classNames.join(' ')}
                    ref={onLayerOpen}
                    onMouseMove={ onHoverStart }
                    onMouseOut={ onHoverEnd }
                >
                    <div className={'inner'}>
                        <header>
                            <div className={'title'}>
                                {selectedVideo.directory.hash ? (
                                    <Link to={`/dir/${selectedVideo.directory.hash}`}
                                          onClick={() => setSelectedVideo(null)}>
                                        {selectedVideo.directory.name} /
                                    </Link>
                                ) : null}
                                &nbsp; {selectedVideo.name}
                            </div>
                            <TagList video={selectedVideo} showAdd/>

                            <div className="settings">
                                <ul>
                                    {selectedVideo.fileType === "video" ? (
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={videoLoop}
                                                   onChange={e => setVideoLoop(e.target.checked)}/>
                                            Loop
                                        </label>
                                    </li>
                                    ) : null}
                                    <li>
                                        <label>
                                            <input type="checkbox" checked={videoAutoPlay}
                                                   onChange={e => setVideoAutoPlay(e.target.checked)}
                                                   disabled={selectedVideo.fileType === 'video' && videoLoop}/>
                                            Auto Play
                                        </label>
                                    </li>
                                </ul>
                            </div>

                            {/*<Link to={`/dir/${selectedVideo.directory?.hash}`}>*/}
                            {/*    {selectedVideo.directory?.path}*/}
                            {/*</Link>*/}

                            <a href="#" onClick={onClose} id={'btn-close'} title="Close">
                                <FontAwesomeIcon icon={faTimes}/>
                            </a>
                        </header>
                        <div className="content">
                            <div className="container">
                                <div className={`base ${fullScreen ? ' fullscreen' : ''}`} ref={containerRef}>
                                    {selectedVideo.fileType === "video" ? (
                                        <VideoPlayer
                                            loop={videoLoop}
                                            autoPlay={videoAutoPlay}
                                            onEnded={onVideoEnded}
                                            fullScreen={fullScreen}
                                            enableFullscreen={toggleFullScreen}
                                            video={selectedVideo}/>
                                    ) : null}

                                    {selectedVideo.fileType === "photo" ? (
                                        <div className="photo-container" onDoubleClick={toggleFullScreen}>
                                            <div className="inner">
                                                <img src={selectedVideo.paths.stream}/>
                                            </div>
                                        </div>
                                    ) : null}

                                    { nav }
                                </div>

                                {files.length && (
                                    <VideoList files={files} loading={dirLoading} onLoad={onSmallListLoaded}
                                               showSort={false}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}