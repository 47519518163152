import {createRef, useContext, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronLeft, faChevronRight,
    faCompress,
    faExpand,
    faPause,
    faPlay,
    faSpinner,
    faVolumeHigh,
    faVolumeXmark
} from "@fortawesome/free-solid-svg-icons";

import './VideoPlayer.scss'
import VideoSeekBar from "./VideoSeekBar";
import {formatSeconds, getVideoLink} from "../../lib/static";
import AppContext from "../../context/AppContext";
import {Link, useNavigate} from "react-router-dom";

type Props = {
    video: VideoFile
    loop?: boolean
    autoPlay?: boolean
    onEnded?: () => void
    fullScreen: boolean
    enableFullscreen: () => void
}

export default ({ video, fullScreen, enableFullscreen, loop = true, autoPlay = false, onEnded = () => null }: Props) => {
    const containerRef = createRef<HTMLDivElement>()
    const videoRef = createRef<HTMLVideoElement>()

    const [ videoLoadingError, setVideoLoadingError ] = useState<boolean>(false)
    const [ totalTime, setTotalTime ] = useState<number | null>(null)
    const [ currentTime, setCurrentTime ] = useState<number | null>(null)
    const [ paused, setPaused ] = useState<boolean>(false)
    const [ muted, setMuted ] = useState<boolean>(false)
    const [ volume, setVolume ] = useState<number>(0)
    const [ ctrlPressed, setCtrlPressed ] = useState<boolean>(false)

    const ctrlRef = useRef<boolean>(false)
    ctrlRef.current = ctrlPressed

    useEffect(() => {
        if (!videoRef.current) {
            setCurrentTime(null)
            setTotalTime(null)
            return
        }

        setMuted(videoRef.current.muted)
        setVolume(videoRef.current.volume)

        videoRef.current.addEventListener('pause', () => setPaused(true))
        videoRef.current.addEventListener('play', () => setPaused(false))
        videoRef.current.addEventListener('ended', onEnded)
        videoRef.current.addEventListener('loadedmetadata', (e: any) => {
            if (!videoRef.current) {
                return
            }
            setCurrentTime(0)
            setTotalTime(videoRef.current.duration)
        })
        videoRef.current.addEventListener('timeupdate', (e: any) => {
            if (!videoRef.current) {
                return
            }
            setCurrentTime(videoRef.current.currentTime)
        })

        const onKeyDown = (e: KeyboardEvent) => {
            if (!videoRef.current) {
                return
            }

            if (e.key === 'Control') {
                setCtrlPressed(true)
            }
        }

        const onKeyUp = (e: KeyboardEvent) => {
            if (!videoRef.current) {
                return
            }

            switch (e.key) {
                case 'Control':
                    setCtrlPressed(false)
                    break
                case 'ArrowLeft':
                    if (ctrlRef.current) {
                        break
                    }
                    e.preventDefault()
                    videoRef.current.currentTime -= 10
                    break
                case 'ArrowRight':
                    if (ctrlRef.current) {
                        break
                    }
                    e.preventDefault()
                    videoRef.current.currentTime += 10
                    break
                case ' ':
                    e.preventDefault()
                    videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
                    break
            }
        }

        window.addEventListener('keydown', onKeyDown)
        window.addEventListener('keyup', onKeyUp)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
            window.removeEventListener('keyup', onKeyUp)
        }
    }, [videoRef]);

    useEffect(() => {
        setVideoLoadingError(false)
        setCurrentTime(null)
        setTotalTime(null)
    }, [ video ])

    const togglePlay = (e: any) => {
        if (!videoRef.current) {
            return
        }
        if (videoRef.current.paused) {
            videoRef.current.play()
        } else {
            videoRef.current.pause()
        }
    }

    const toggleMute = (e: any) => {
        if (!videoRef.current) {
            return
        }
        videoRef.current.muted = !videoRef.current.muted
        setMuted(videoRef.current.muted)
    }

    const seekTo = (ts: number) => {
        if (!videoRef.current) {
            return
        }
        videoRef.current.currentTime = ts
    }

    const onVolumeChange = (e: any) => {
        const value = e.target.value / 100
        if (videoRef.current) {
            videoRef.current.volume = value
            setVolume(value)
        }
    }

    const onVideoError = (e: any) => {
        setVideoLoadingError(true)
    }

    const classNames = ['video-container']
    if (paused) classNames.push('paused')
    if (muted) classNames.push('muted')
    if (!totalTime) classNames.push('loading')

    return (
        <div className={classNames.join(' ')} ref={containerRef} style={{position: 'relative'}}>
            <video autoPlay={true}
                   key={video.paths.stream}
                   ref={videoRef}
                   controls={false}
                   onError={onVideoError}
                   onClick={togglePlay}
                   loop={loop}
                   onDoubleClick={enableFullscreen}>
                <source src={video.paths.stream}></source>
            </video>
            {videoLoadingError ? (
                <div className="video-loading-error">
                    <div className="message">
                        Failed to load this video, try to re-index the current directory and start it again.
                    </div>
                </div>
            ) : (
                <div className="controls">
                    <div className="controls-content">
                        {totalTime && (
                            <VideoSeekBar video={video} currentTime={currentTime} totalTime={totalTime}
                                          seekTo={seekTo}/>
                        )}
                        <div className="control-buttons">
                            {!totalTime ? (
                                <span><FontAwesomeIcon icon={faSpinner} spin/></span>
                            ) : (
                                <>
                                    <div className="left">
                                        <button onClick={togglePlay}>
                                            <FontAwesomeIcon icon={paused ? faPlay : faPause}/>
                                        </button>
                                        <span>{formatSeconds(currentTime)} / {formatSeconds(totalTime)}</span>
                                    </div>
                                    <div className="right">
                                        <div className="volume-container">
                                            <div className="input-wrap">
                                                <input type="range" disabled={muted} min={0} max={100} step="1"
                                                       value={volume * 100} onChange={onVolumeChange}/>
                                            </div>
                                            <button onClick={toggleMute}>
                                                <FontAwesomeIcon icon={muted ? faVolumeXmark : faVolumeHigh}/>
                                            </button>
                                        </div>

                                        <button onClick={enableFullscreen}>
                                            <FontAwesomeIcon icon={fullScreen ? faCompress : faExpand}/>
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}