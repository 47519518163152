type PaginatedVidoes = {
    count: number,
    files: VideoFile[],
}

type AuthInfo = {
    loggedIn: boolean
    username: string
}

type LoginResponse = {
    error?: string
    sessionId?: string
}

class API
{
    private baseUrl: string
    private sessionId: string;

    constructor() {
        this.baseUrl = (process.env.REACT_APP_BASE_URL || '') + '/api'

        this.sessionId = localStorage.getItem('session-id') || ''
    }

    public async getAuthInfo(): Promise<AuthInfo> {
        return await this.get('/info')
    }

    public async login(username: string, password: string): Promise<LoginResponse> {
        return await this.post('/login', {
            username,
            password,
        })
    }

    public async getProcesList(): Promise<Processlist> {
        return await this.get('/processlist');
    }

    public async getDirectories(): Promise<VideoDirectory[]> {
        return await this.get('/directories');
    }

    public async indexDirectory(path: string) {
        return await this.post(`/index/directory`, {
            path,
        })
    }

    public async indexAll() {
        return await this.post(`/index`)
    }

    public async getFile(id: string): Promise<VideoFile> {
        return await this.get(`/file/${id}`);
    }

    public async getDirectory(
        hash: string,
        maxFiles = 0,
        sortField: string,
        sortDir: string,
        fileType?: string|null,
    ): Promise<VideoDirectory> {
        let url = `/directory/${hash}?sortField=${sortField}&sortDir=${sortDir}`
        if (maxFiles) {
            url += `&maxFiles=${maxFiles}`
        }
        if (fileType) {
            url += `&type=${fileType}`
        }
        return await this.get(url);
    }

    public async getFiles(
        directoryHash: string,
        offset: number,
        limit: number,
        sortField: string,
        sortDir: string,
        fileType?: string|null,
    ): Promise<{ count: number, files: VideoFile[] }> {
        let url = `/directory/${directoryHash}/files?offset=${offset}&limit=${limit}&sortField=${sortField}&sortDir=${sortDir}`
        if (fileType) {
            url += `&type=${fileType}`
        }
        return await this.get(url);
    }

    public async getVideosByTags(
        tags: string[],
        offset: number,
        limit: number,
        sortField: string,
        sortDir: string,
        fileType?: string|null,
    ): Promise<PaginatedVidoes> {
        let url = `/video/tags/${tags.join(',')}?offset=${offset}&limit=${limit}&sortField=${sortField}&sortDir=${sortDir}`
        if (fileType) {
            url += `&type=${fileType}`
        }
        return await this.get(url)
    }

    public async searchVideo(
        term: string,
        offset: number,
        limit: number,
        sortField: string,
        sortDir: string,
        fileType?: string|null,
    ): Promise<PaginatedVidoes> {
        let url = `/video/search/${term}?offset=${offset}&limit=${limit}&sortField=${sortField}&sortDir=${sortDir}`
        if (fileType) {
            url += `&type=${fileType}`
        }
        return await this.get(url)
    }

    public async getFileFavourites(
        offset: number,
        limit: number,
        sortField: string,
        sortDir: string,
        fileType?: string|null,
    ): Promise<PaginatedVidoes> {
        let url = `/video?offset=${offset}&limit=${limit}&sortField=${sortField}&sortDir=${sortDir}&favourite=1`
        if (fileType) {
            url += `&type=${fileType}`
        }
        return await this.get(url)
    }

    public async getAllTags(): Promise<string[]> {
        return await this.get(`/tags`);
    }

    public async setTags(hash: string, tags: string[]) {
        return await this.post(`/file/${hash}/tags`, {
            tags,
        })
    }

    async bulkAddTags(videoIds: string[], tags: string[]) {
        return await this.post(`/video/bulk/tags/add`, {
            videoIds,
            tags,
        })
    }

    async bulkRemoveTags(videoIds: string[], tags: string[]) {
        return await this.post(`/video/bulk/tags/remove`, {
            videoIds,
            tags,
        })
    }

    async updateFileFavouriteFlag(hash: string, isFavourite: boolean) {
        return await this.post(`/file/${hash}/favourite`, {
            isFavourite
        })
    }

    async removeFilesIndex(videoIds: string[]) {
        return await this.post(`/removeIndex`, {
            videoIds,
        })
    }

    async removeFiles(videoIds: string[]) {
        return await this.post(`/delete`, {
            videoIds,
        })
    }

    private async get<T>(path: string): Promise<T>
    {
        this.sessionId = localStorage.getItem('session-id') || ''
        console.log('GET', path, this.sessionId)

        const headers: HeadersInit = {}
        if (this.sessionId) {
            headers['Authorization'] = `Bearer ${this.sessionId}`
        }

        const res = await fetch(this.baseUrl + path, {
            headers,
        })
        return await res.json()
    }

    private async post<T>(path: string, params: any = {}): Promise<T>
    {
        this.sessionId = localStorage.getItem('session-id') || ''

        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        if (this.sessionId) {
            headers['Authorization'] = `Bearer ${this.sessionId}`
        }

        const res = await fetch(this.baseUrl + path, {
            method: 'POST',
            body: JSON.stringify(params),
            headers,
        })
        return await res.json()
    }

    setSessionId(sessionId: string) {
        this.sessionId = sessionId
        localStorage.setItem('session-id', sessionId)
    }
}

let api = new API()

export const useApi = () => {
    return api
}