import {createRef, useContext, useEffect, useRef, useState} from "react";
import FilePreview from "./FilePreview";

import './VideoList.scss'
import {videoSortMethods, VideoSortMethodType} from "../lib/videoSortMethods";
import AppContext from "../context/AppContext";
import {getVideoLink} from "../lib/static";
import BulkAddTag from "./bulk-actions/BulkAddTag";
import BulkRemoveTag from "./bulk-actions/BulkRemoveTag";
import {useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDownAZ,
    faArrowDownZA,
    faArrowUpAZ,
    faArrowUpZA,
    faSortAsc,
    faSpinner
} from "@fortawesome/free-solid-svg-icons";
import {useApi} from "../api";

type Props = {
    loading: boolean
    files: VideoFile[]
    onLoad?: () => void
    showSort?: boolean
    showDirectory?: boolean
    canBeSelected?: boolean
}

export default ({ loading, files, onLoad, showSort = true, showDirectory = false, canBeSelected = false }: Props) => {
    const location = useLocation()

    const api = useApi()

    const {
        setSelectedVideo, reloadView, sortType, setSortType, limitToFileType, setLimitToFileType,
        fetchingAdditionalFiles, maybeFetchAdditionalFiles, hasMoreFiles,
    } = useContext(AppContext)

    const [ selectedVideos, setSelectedVideos ] = useState<VideoFile[]>([])

    const [ withoutTags, setWithoutTags ] = useState<boolean>(localStorage.getItem('only-without-tags') === '1')
    const [ onlyFavourites, setOnlyFavourites ] = useState<boolean>(localStorage.getItem('only-favourite') === '1')

    const [ bulkAction, setBulkAction ] = useState<string>('')

    const [ ctrlPressed, setCtrlPressed ] = useState<boolean>(false)

    const [ lastSelectAction, setLastSelectAction ] = useState<{ selected: boolean, video: VideoFile }|null>(null)
    const [ shiftPressed, setShiftPressed ] = useState<boolean>(false)

    const selectedVideosRef = useRef<VideoFile[]>([])
    selectedVideosRef.current = selectedVideos

    const loadMoreRef = createRef<HTMLDivElement>()

    const onSortChange = (e: any) => {
        setSortType(e.target.value as VideoSortMethodType)
        localStorage.setItem('video-sort-type', e.target.value)
    }

    const classNames = ['file-list']
    if (loading) {
        classNames.push('loading')
    }

    useEffect(() => {
        if (bulkAction === 'clear-index') {
            setBulkAction('')
            ;(async () => {
                if (window.confirm('Are you sure?')) {
                    await api.removeFilesIndex(selectedVideos.map(v => v.hash))
                    reloadView(undefined)
                }
            })()
        }
        if (bulkAction === 'remove-files') {
            setBulkAction('')
            ;(async () => {
                if (window.confirm('Are you sure?')) {
                    await api.removeFiles(selectedVideos.map(v => v.hash))
                    reloadView(undefined)
                }
            })()
        }
    }, [bulkAction]);

    const setVideoSelectionMultiple = (videos: VideoFile[], selected: boolean) => {
        if (selected) {
            setSelectedVideos([ ...selectedVideosRef.current, ...videos ])
        } else {
            const ids = videos.map(v => v.hash)
            setSelectedVideos(selectedVideosRef.current.filter(v => !ids.includes(v.hash)))
        }

        const last = videos[videos.length - 1]
        if (last) {
            setLastSelectAction({ selected, video: last })
        }
    }

    const setVideoSelection = (video: VideoFile, selected: boolean) => {
        setVideoSelectionMultiple([video], selected)
    }

    const toggleVideoSelection = (video: VideoFile) => {
        if (selectedVideos.includes(video)) {
            setVideoSelection(video, false)
        } else {
            setVideoSelection(video, true)
        }
    }

    const onClick = (video: VideoFile) => {
        if (canBeSelected && ctrlPressed) {
            toggleVideoSelection(video)
            return
        }
        if (canBeSelected && shiftPressed) {
            if (!lastSelectAction) {
                toggleVideoSelection(video)
                return
            }
            let indexLast: number|null = null
            let indexNow: number|null = null

            for (const index in files) {
                if (files[index] === lastSelectAction.video) {
                    indexLast = parseInt(index)
                    continue
                }
                if (files[index] === video) {
                    indexNow = parseInt(index)
                }
                if (indexLast !== null && indexNow != null) {
                    break
                }
            }

            if (indexLast !== null && indexNow !== null) {
                let shouldBeSelected = lastSelectAction.selected
                let filesToChange: VideoFile[] = []

                if (indexLast < indexNow) {
                    for (let i = indexLast; i <= indexNow; i++) {
                        const file = files[i]
                        if (typeof file !== 'undefined') {
                            filesToChange.push(file)
                        } else {
                            console.log('no file with index', i)
                        }
                    }
                } else {
                    for (let i = indexLast; i >= indexNow; i--) {
                        const file = files[i]
                        if (typeof file !== 'undefined') {
                            filesToChange.push(file)
                        } else {
                            console.log('no file with index', i)
                        }
                    }
                }

                setVideoSelectionMultiple(filesToChange, shouldBeSelected)
            }

            console.log({ indexLast, indexNow })
            return
        }

        setSelectedVideo(video)
        window.history.pushState(null, '', getVideoLink(video.hash))
    }

    const onVideoSelect = (video: VideoFile, selected: boolean) => {
        toggleVideoSelection(video)
    }

    const getClassName = (video: VideoFile) => {
        if (selectedVideos.length > 0 && !selectedVideos.includes(video)) {
            return 'not-selected'
        }
        return ''
    }

    const toggleAll = (e: any) => {
        e.preventDefault()

        const select = !selectedVideos.map(v => v.hash).includes(files[0].hash)

        if (select) {
            setSelectedVideos([ ...files ])
        } else {
            setSelectedVideos([])
        }
    }

    const tagsAdded = (tags: string[]) => {
        reloadView(undefined)
    }

    const tagsRemoved = (tags: string[]) => {
        reloadView(undefined)
    }

    useEffect(() => {
        if (onLoad) {
            onLoad()
        }

        if (canBeSelected) {
            const onKeyDown = (e: any) => {
                if (e.key === 'Control') {
                    setCtrlPressed(true)
                }
                if (e.key === 'Shift') {
                    setShiftPressed(true)
                }
            }
            const onKeyUp = (e: any) => {
                if (e.key === 'Control') {
                    setCtrlPressed(false)
                }
                if (e.key === 'Shift') {
                    setShiftPressed(false)
                }
            }

            window.addEventListener('keydown', onKeyDown)
            window.addEventListener('keyup', onKeyUp)

            return () => {
                window.removeEventListener('keydown', onKeyDown)
                window.removeEventListener('keyup', onKeyUp)
            }
        }
    }, []);

    useEffect(() => {
        setSelectedVideos([])
    }, [ files ]);

    useEffect(() => {
        localStorage.setItem('only-without-tags', withoutTags ? '1' : '0')
    }, [ withoutTags ]);

    useEffect(() => {
        localStorage.setItem('only-favourites', onlyFavourites ? '1' : '0')
    }, [ onlyFavourites ]);

    useEffect(() => {
        if (loadMoreRef.current) {
            const check = () => {
                if (loadMoreRef.current) {
                    const scroll = window.innerHeight + window.scrollY
                    const refTop = loadMoreRef.current.offsetTop

                    if (hasMoreFiles() && scroll >= refTop - 800) {
                        maybeFetchAdditionalFiles()
                    }
                }
            }

            window.addEventListener('scroll', check)

            return () => {
                window.removeEventListener('scroll', check)
            }
        }
    }, [ loadMoreRef ]);

    const changeFileType = (e: any, fileType: string | null) => {
        e.preventDefault()
        setLimitToFileType(fileType)
        localStorage.setItem('file-type', fileType || '')
    }

    const isDir = location.pathname === '/' || location.pathname.startsWith('/dir/')

    const defaultFilter = (file: VideoFile) => {
        if (limitToFileType && file.fileType !== limitToFileType) {
            return false
        }
        if (isDir && withoutTags && file.tags && file.tags.length > 0) {
            return false
        }
        if (isDir && onlyFavourites && !file.isFavourite) {
            return false
        }

        return true
    }

    return (
        <div className="video-list">
            {files?.length > 0 ? (
                <>
                    {showSort && (
                        <>
                            <span className="sort-icon">
                                { videoSortMethods[sortType].dir === 'asc' ? (
                                    <FontAwesomeIcon icon={ faArrowDownAZ }/>
                                ) : (
                                    <FontAwesomeIcon icon={ faArrowUpZA }/>
                                )}
                            </span>

                            <select value={sortType} onChange={onSortChange} disabled={loading}>
                                {Object.keys(videoSortMethods).map(type => (
                                    <option key={type} value={type}>{videoSortMethods[type].name}</option>
                                ))}
                            </select>

                            {bulkAction === 'add-tag' && selectedVideos.filter(defaultFilter).length ? (
                                <BulkAddTag videoIds={selectedVideos.filter(defaultFilter).map(v => v.hash)}
                                            onChanged={tagsAdded}
                                            onClose={() => setBulkAction('')}/>
                            ) : null}

                            {bulkAction === 'remove-tag' && selectedVideos.filter(defaultFilter).length ? (
                                <BulkRemoveTag videos={selectedVideos.filter(defaultFilter)}
                                               onChanged={tagsRemoved}
                                               onClose={() => setBulkAction('')}/>
                            ) : null}

                            <div className="selection-menu">
                                <>
                                    {selectedVideos.filter(defaultFilter).length ? (
                                        <select value={bulkAction} onChange={e => setBulkAction(e.target.value)}>
                                            <option value="">Bulk action</option>
                                            <option value="add-tag">Add Tags</option>
                                            <option value="remove-tag">Remove Tag</option>
                                            <option value="clear-index">Clear index</option>
                                            <option value="remove-files">Remove files (!)</option>
                                        </select>
                                    ) : null}

                                    <button onClick={toggleAll}>
                                        {selectedVideos.filter(defaultFilter).length ? 'Deselect all' : 'Select all'}
                                    </button>

                                    {selectedVideos.filter(defaultFilter).length ? (
                                        <div className="cnt">
                                            {(selectedVideos.filter(defaultFilter).length == 1
                                                ? '{} video selected'
                                                : '{} videos selected').replace('{}', selectedVideos.filter(defaultFilter).length + '')}
                                        </div>
                                    ) : ''}
                                </>
                            </div>

                            {isDir ? (
                                <div>
                                    <label>
                                        <input type="checkbox" checked={withoutTags}
                                               onChange={e => setWithoutTags(e.target.checked)}/>
                                        Only without tags
                                    </label>
                                    <label>
                                        <input type="checkbox" checked={onlyFavourites}
                                               onChange={e => setOnlyFavourites(e.target.checked)}/>
                                        Only favourites
                                    </label>
                                </div>
                            ) : null}

                            <div className="file-type-selection">
                                <div className="inner">
                                    <a href="#" onClick={e => changeFileType(e, null)}
                                       className={limitToFileType == null ? 'active' : ''}>
                                        All
                                    </a>
                                    <a href="#" onClick={e => changeFileType(e, "video")}
                                       className={limitToFileType == "video" ? 'active' : ''}>
                                        Videos
                                    </a>
                                    <a href="#" onClick={e => changeFileType(e, "photo")}
                                       className={limitToFileType == "photo" ? 'active' : ''}>
                                        Photos
                                    </a>
                                </div>
                            </div>
                        </>
                    )}

                    <ul className={classNames.join(' ')}>
                        {files ? (
                            <>
                                {files.map(file => (
                                    <li key={file.hash} className="file" style={{ display: !defaultFilter(file) ? 'none' : 'block' }}>
                                        <FilePreview file={file}
                                                     showDirectory={showDirectory}
                                                     className={getClassName(file)}
                                                     canBeSelected={canBeSelected}
                                                     selected={selectedVideos.includes(file)}
                                                     onSelect={(selected: boolean) => onVideoSelect(file, selected)}
                                                     onClick={() => onClick(file)}/>
                                    </li>
                                ))}
                            </>
                        ) : <></>}
                    </ul>
                </>
            ) : <></>}

            <div ref={loadMoreRef}/>

            { fetchingAdditionalFiles ? (
                <FontAwesomeIcon icon={ faSpinner } spin size={'3x'} style={{ position: 'fixed', bottom: '10px', left: '10px', zIndex: 99 }}/>
            ) : null }
        </div>
    )
}
