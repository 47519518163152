import React, {useEffect, useState} from 'react';
import './App.scss';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import AppContext from "./context/AppContext";
import {useApi} from "./api";
import VideoLayer from "./components/VideoLayer";
import Header from "./components/Header";
import FileQueue from "./components/FileQueue";
import {ProcessListType, useProcessListClient} from "./api/ProcessListClient";
import ProcessListBubble from "./components/ProcessListBubble";
import FileBrowser from "./views/FileBrowser";
import {videoSortMethods, VideoSortMethodType} from "./lib/videoSortMethods";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons";
import AuthWrapper from "./AuthWrapper";

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const api = useApi()

  const [ loggedIn, setLoggedIn ] = useState<boolean>(false)
  const [ username, setUsername ] = useState<string|null>(null)
  const [ currentDir, setCurrentDir ] = useState<VideoDirectory | null>(null)
  const [ dirLoading, setDirLoading ] = useState<boolean>(false)
  const [ directories, setDirectories ] = useState<VideoDirectory[]>([])
  const [ files, setFiles ] = useState<VideoFile[]>([])
  const [ prevVideo, setPrevVideo ] = useState<VideoFile | null>(null)
  const [ selectedVideo, setSelectedVideo ] = useState<VideoFile | null>(null)
  const [ nextVideo, setNextVideo ] = useState<VideoFile | null>(null)
  const [ processList, setProcessList ] = useState<ProcessListType | null>(null)
  const [ darkMode, setDarkMode ] = useState<boolean>(!!localStorage.getItem('darkmode') || false)
  const [ tags, setTags ] = useState<string[]>([])
  const [ videoLoop, setVideoLoop ] = useState<boolean>(true)
  const [ videoAutoPlay, setVideoAutoPlay ] = useState<boolean>(false)
  const [ sortType, setSortType ] = useState<VideoSortMethodType>(localStorage.getItem('video-sort-type') as VideoSortMethodType || 'none')
  const [ limitToFileType, setLimitToFileType ] = useState<string | null>(localStorage.getItem('file-type') || null)
  const [ loadedFilesNum, setLoadedFilesNum ] = useState<number>(0)
  const [ fetchingAdditionalFiles, setFetchingAdditionalFiles ] = useState<boolean>(false)
  const [ maxFiles, setMaxFiles ] = useState<number>(0)

  const videosPerPage = 60

  const loadDirectory = (ev: any, dir: VideoDirectory | null) => {
    setSelectedVideo(null)

    ev.preventDefault()
    setCurrentDir(dir)
    if (dir) {
      navigate(`/dir/${dir.hash}`)
    } else {
      navigate('/')
    }
  }

  const reloadView = (e: any|undefined = undefined) => {
    if (!loggedIn) {
      return
    }

    if (e != undefined) {
      e.preventDefault()
      e.stopPropagation()
    }

    (async () => {
      setDirLoading(true)

      const sort = videoSortMethods[sortType]

      if (location.pathname === '/') {
        const res = await api.getDirectories()
        setCurrentDir(null)
        setDirectories(res || [])
        setFiles([])
      }
      else if (location.pathname.startsWith('/tags/')) {
        let path = location.pathname
        if (path.startsWith('/')) {
          path = path.substring(1)
          const chunks = path.split('/')
          const tags = chunks[1].split(',').map(t => t.trim()).filter(t => !!t)

          setCurrentDir(null)
          setDirectories([])

          if (tags.length > 0) {
            await (async () => {
              setDirLoading(true)

              const res = await api.getVideosByTags(tags, 0, videosPerPage, sort.field, sort.dir, limitToFileType)

              if (res) {
                setFiles(res.files || [])
                setLoadedFilesNum(res.files?.length || 0)
                setMaxFiles(res.count)
              } else {
                setFiles([])
                setMaxFiles(0)
              }

              setDirLoading(false)
            })()
          }
        }
      }
      else if (location.pathname.startsWith('/search/')) {
        let path = location.pathname
        if (path.startsWith('/')) {
          path = path.substring(1)
          const chunks = path.split('/')
          const term = chunks[1]

          setCurrentDir(null)
          setDirectories([])
          setFiles([])

          if (term.length > 0) {
            await (async () => {
              setDirLoading(true)

              const res = await api.searchVideo(term, 0, videosPerPage, sort.field, sort.dir, limitToFileType)

              if (res) {
                setFiles(res.files)
                setMaxFiles(res.count)
              } else {
                setFiles([])
                setMaxFiles(0)
              }

              setDirLoading(false)
            })()
          }
        }
      }
      else if (location.pathname.startsWith('/favourites')) {
        let path = location.pathname
        if (path.startsWith('/')) {
          setCurrentDir(null)
          setDirectories([])
          setFiles([])

          await (async () => {
            setDirLoading(true)

            const res = await api.getFileFavourites(0, videosPerPage, sort.field, sort.dir, limitToFileType)

            if (res) {
              setFiles(res.files)
              setMaxFiles(res.count)
            } else {
              setFiles([])
              setMaxFiles(0)
            }

            setDirLoading(false)
          })()
        }
      }
      else if (location.pathname.startsWith('/dir/'))
      {
        let path = location.pathname
        if (path.startsWith('/')) {
          path = path.substring(1)
        }
        if (path.endsWith('/')) {
          path = path.substring(0, path.length - 2)
        }
        const hash = path.split('/').pop()

        const res = await api.getDirectory(hash as string, videosPerPage, sort.field, sort.dir, limitToFileType)

        setCurrentDir(res)
        setDirectories(res.children || [])
        setLoadedFilesNum(res.files?.length)
        setMaxFiles(res.fileCount)
        setFiles(res.files || [])

        if (res) {
          const [ url, query ] = window.location.href.split('?')
          if (query) {
            const queryParams = query.split('&').reduce((res: any, chunk: string) => {
              const [ key, value ] = chunk.split('=')
              if (key && value) {
                res[key] = value
              }
              return res
            }, {})

            if (typeof queryParams['show'] !== 'undefined') {
              const videoId = queryParams['show']
              const video = res.files.find(v => v.hash === videoId)
              if (video) {
                setSelectedVideo(video)
              }
            }
          }
        }
      }

      setDirLoading(false)
    })()
  }

  useEffect(reloadView, [loggedIn, location, sortType, limitToFileType]);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname.startsWith('/tags') || location.pathname.startsWith('/search')) {
      setDirectories([])
      setFiles([])
      setLoadedFilesNum(0)
      setMaxFiles(0)
    }
  }, [ location ]);

  useEffect(() => {
    if (selectedVideo) {
      document.body.classList.add('video-open')
    } else {
      document.body.classList.remove('video-open')
    }
  }, [ selectedVideo ])

  useEffect(() => {
    let firstVideo: VideoFile | null = null
    let lastVideo: VideoFile | null = null
    let prevVideo: VideoFile | null = null
    let nextVideo: VideoFile | null = null

    if (files && files.length) {
      const sortedFiles = files
          .filter(file => {
            if (limitToFileType && file.fileType !== limitToFileType) {
              return false
            }
            return true
          })

      firstVideo = sortedFiles[0]
      lastVideo = sortedFiles[sortedFiles.length - 1]

      let found = false
      for (const video of sortedFiles) {
        if (video.hash === selectedVideo?.hash) {
          found = true
          continue
        }
        if (found) {
          nextVideo = video
          break
        }
        prevVideo = video
      }

      if (!prevVideo && lastVideo) {
        prevVideo = lastVideo
      }
      if (!nextVideo && firstVideo) {
        nextVideo = firstVideo
      }

      setPrevVideo(prevVideo)
      setNextVideo(nextVideo)
    } else {
      setPrevVideo(null)
      setNextVideo(null)
    }
  }, [ files, selectedVideo, limitToFileType ]);

  const client = useProcessListClient()

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
    localStorage.setItem('darkmode', darkMode ? '1' : '0')
  }, [darkMode]);

  useEffect(() => {
    if (!loggedIn) {
      return
    }

    const onStatus = (status: ProcessListType) => {
      setProcessList(status)
    }

    client.addListener(onStatus)

    ;(async () => {
      const tags = await api.getAllTags()
      setTags(tags || [])
    })()

    return () => {
      client.removeListener(onStatus)
    }
  }, [ loggedIn ])

  const startIndexing = async () => {
    if (currentDir) {
      await api.indexDirectory(currentDir.path)
      return
    }
    await api.indexAll()
  }

  const maybeFetchAdditionalFiles = async () => {
    if (!hasMoreFiles()) {
      return
    }

    if (fetchingAdditionalFiles) {
      return
    }

    const sort = videoSortMethods[sortType]

    if (currentDir) {
      setFetchingAdditionalFiles(true)

      const res = await api.getFiles(currentDir.hash, loadedFilesNum, videosPerPage, sort.field, sort.dir, limitToFileType)
      if (res) {
        const tmp = [ ...files, ...res.files ]
        setFiles(tmp)
        setLoadedFilesNum(tmp.length)
      }
      setFetchingAdditionalFiles(false)
    }

    if (location.pathname.startsWith('/tags/')) {
      let path = location.pathname
      if (path.startsWith('/')) {
        path = path.substring(1)
        const chunks = path.split('/')
        const tags = chunks[1].split(',').map(t => t.trim()).filter(t => !!t)

        if (tags.length > 0) {
          await (async () => {
            setFetchingAdditionalFiles(true)

            const res = await api.getVideosByTags(tags, loadedFilesNum, videosPerPage, sort.field, sort.dir, limitToFileType)
            if (res) {
              const tmp = [...files, ...(res.files || [])]
              setFiles(tmp)
              setLoadedFilesNum(tmp.length || 0)
            }
            setFetchingAdditionalFiles(false)
          })()
        }
      }
    }

    if (location.pathname.startsWith('/search/')) {
      let path = location.pathname
      if (path.startsWith('/')) {
        path = path.substring(1)
        const chunks = path.split('/')
        const term = chunks[1]

        if (tags.length > 0) {
          await (async () => {
            setFetchingAdditionalFiles(true)

            const res = await api.searchVideo(term, loadedFilesNum, videosPerPage, sort.field, sort.dir, limitToFileType)
            if (res) {
              const tmp = [ ...files, ...res.files ]
              setFiles(tmp)
              setLoadedFilesNum(tmp.length)
            }
            setFetchingAdditionalFiles(false)
          })()
        }
      }
    }
  }

  const hasMoreFiles = () => {
    return maxFiles > loadedFilesNum
  }

  const scrollToTop = (e: any) => {
    e.preventDefault()
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  const appContext = {
    loggedIn,
    username,
    dirLoading,
    currentDir,
    setCurrentDir,
    loadDirectory,
    directories,
    files,
    selectedVideo,
    setSelectedVideo,
    processList,
    darkMode,
    setDarkMode,
    startIndexing,
    tags,
    setTags,
    prevVideo,
    nextVideo,
    videoLoop,
    videoAutoPlay,
    setVideoLoop,
    setVideoAutoPlay,
    reloadView,
    sortType,
    setSortType,
    limitToFileType,
    setLimitToFileType,
    fetchingAdditionalFiles,
    maybeFetchAdditionalFiles,
    hasMoreFiles,
    maxFiles,
  }

  const onLoggedIn = (username: string, sessionId: string|null = null) => {
    if (sessionId) {
      api.setSessionId(sessionId)
    }

    setLoggedIn(true)
    setUsername(username)
  }

  return (
    <div id="app">
      <AppContext.Provider value={appContext}>
        <AuthWrapper onLoggedIn={ onLoggedIn }>
          <Header/>

          <Routes>
            <Route path="/" key="home" element={<FileBrowser/>}/>
            <Route path="/dir/:dirHash" key="directory" element={<FileBrowser/>}/>
            <Route path="/tags" key="tags" element={<FileBrowser/>}/>
            <Route path="/tags/:tags" key="tags" element={<FileBrowser/>}/>
            <Route path="/favourites" key="favourites" element={<FileBrowser/>}/>
            <Route path="/search/" key="search" element={<FileBrowser/>}/>
            <Route path="/search/:term" key="search" element={<FileBrowser/>}/>
            <Route path="/queue" key="queue" element={<FileQueue/>}/>
          </Routes>

          <VideoLayer/>
          <ProcessListBubble/>

          <a href="#" id="scroll-to-top" onClick={ scrollToTop }>
            <FontAwesomeIcon icon={ faChevronUp }/>
          </a>
        </AuthWrapper>
      </AppContext.Provider>
    </div>
  );
}

export default App;
