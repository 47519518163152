import React, {useContext, useEffect, useState} from "react";
import AppContext from "../context/AppContext";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsRotate, faHome} from "@fortawesome/free-solid-svg-icons";
import HeaderItemList, {HeaderMenuItem} from "../HeaderItemList";

export default () => {
    const { currentDir, dirLoading, reloadView, fetchingAdditionalFiles } = useContext(AppContext)

    const [ reversedParents, setReversedParents ] = useState<VideoDirectory[]>([])

    useEffect(() => {
        let parents: VideoDirectory[] = []
        if (currentDir && currentDir.parents) {
            parents = [ ...currentDir.parents ]
            parents.reverse()
        }
        setReversedParents(parents)
    }, [currentDir]);

    const location = useLocation()

    const items: HeaderMenuItem[] = []

    const isHome = location.pathname === '/'
    const isDir = location.pathname.startsWith('/dir/')
    const isQueue = location.pathname === '/queue'
    const isTags = location.pathname.startsWith('/tags/')
    const isSearch = location.pathname.startsWith('/search/')
    const isFavourites = location.pathname.startsWith('/favourites')

    const isBrowserPage = !isQueue

    items.push({ key: 'home', active: isHome || isDir, element: (
            <Link to="/">
                Files
            </Link>
        )})
    items.push({ key: 'files', active: isTags, element: (
            <Link to="/tags/">
                Tags
            </Link>
        )})
    items.push({ key: 'favourites', active: isFavourites, element: (
            <Link to="/favourites">
                Favourites
            </Link>
        )})
    items.push({ key: 'search', active: isSearch, element: (
            <Link to="/search/">
                Search
            </Link>
        )})

    return (
        <>
            {isBrowserPage && (
                <ul>
                    <li>
                        <a href="#" onClick={e => reloadView(e)}>
                            <FontAwesomeIcon icon={faArrowsRotate} spin={dirLoading || fetchingAdditionalFiles}/>
                        </a>
                    </li>
                </ul>
            )}
            <HeaderItemList
                className="breadcrumbs"
                collisionWatchSelector="#app-title"
                items={ items }/>
        </>
    )
}