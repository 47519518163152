import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar} from "@fortawesome/free-solid-svg-icons";
import './FavStar.scss'
import {useApi} from "../api";
import {useState} from "react";

interface Props {
    file: VideoFile
    onChange?: () => void
}

export default (props: Props) => {
    const api = useApi()

    const [ disabled, setDisabled ] = useState<boolean>(false)

    const onClick = async (e: any) => {
        e.preventDefault()
        e.stopPropagation()

        if (disabled) {
            return
        }
        // if (window.confirm(props.file.isFavourite ? 'Would you really like to unset the favourite status?' : 'Would you really like to set the favourite status?')) {
            setDisabled(true)
            await api.updateFileFavouriteFlag(props.file.hash, !props.file.isFavourite)
            if (props.onChange) {
                props.onChange()
            }
            props.file.isFavourite = !props.file.isFavourite
            setDisabled(false)
        // }
    }

    let className = props.file.isFavourite ? 'checked' : 'unchecked'
    if (disabled) {
        className = 'disabled'
    }

    return (
        <div className={`fav-star ${className}`} onClick={ onClick }>
            <FontAwesomeIcon icon={ faStar }/>
        </div>
    )
}