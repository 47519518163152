import {useContext, useEffect, useRef, useState} from "react";

import './FilePreview.scss'
import {formatSeconds} from "../lib/static";
import AppContext from "../context/AppContext";
import TagList from "./TagList";
import {Link, useNavigate, useNavigation} from "react-router-dom";
import FavStar from "./FavStar";

type Props = {
    file: VideoFile
    onClick: () => void
    selected?: boolean
    onSelect?: ((selected: boolean) => void) | null
    className?: string
    showDirectory: boolean
    canBeSelected?: boolean
}

export default ({ file, onClick, className, selected = false, onSelect = null, showDirectory = false, canBeSelected = false }: Props) => {
    const { selectedVideo } = useContext(AppContext)
    const navigate = useNavigate()

    const [ hover, setHover ] = useState<boolean>(false)
    const [ checked, setChecked ] = useState<boolean>(selected)
    const [ isInView, setIsInView ] = useState<boolean>(false)

    const containerRef = useRef(null)

    const classNames = []
    if (className) {
        classNames.push(className)
    }
    if (selectedVideo?.hash === file.hash) {
        classNames.push('selected')
    }
    if (selected) {
        classNames.push('checked')
    }
    if (showDirectory && file.directory.hash) {
        classNames.push('with-directory')
    }

    const onSelectionChange = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setChecked(!selected)
    }

    useEffect(() => {
        if (onSelect) {
            onSelect(checked)
        }
    }, [ checked ]);

    useEffect(() => {
        const observer = new IntersectionObserver(() => {
            setIsInView(true)
        })

        if (containerRef.current) observer.observe(containerRef.current)

        return () => {
            if (containerRef.current) observer.unobserve(containerRef.current)
        }
    }, [containerRef]);

    const onFileClick = (ev: any) => {
        ev.preventDefault()
        onClick()
    }

    const imageStyle = {
        backgroundImage: isInView ? `url(${file.paths.thumbnail})` : '',
    }

    const onDirClick = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        console.log('foo', e.target.getAttribute('href'))
        navigate(e.target.getAttribute('href'))
    }

    return (
        <div className={ `file-preview ${classNames.join(' ')}` } ref={containerRef}>
            { canBeSelected && onSelect && (
                <input type="checkbox" checked={ selected } onChange={ onSelectionChange }/>
            )}

            <FavStar file={file}/>

            <TagList video={file} showAdd={true}/>

            <a className="file" href={file.paths.stream} onClick={ onFileClick } target="_blank" onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} data-id={file.hash}>
                <div className="image-wrap">
                    <div className="image" style={ imageStyle }/>
                    { hover && (
                        <div className="gif" style={{backgroundImage: `url(${file.paths.gif || file.paths.thumbnail}`}}/>
                    )}
                    { typeof file.meta.duration !== 'undefined' ? (
                        <div className="duration">{formatSeconds(file.meta.duration)}</div>
                    ) : null}
                </div>
                <div className="meta">
                { showDirectory && file.directory.hash ? (
                        <a href={ `/dir/${file.directory.hash}` } className="directory" onClick={ onDirClick }>
                            {file.directory.name}
                        </a>
                    ) : null }
                    <div className="filename">{file.name}</div>
                </div>
            </a>
        </div>
    )
}
